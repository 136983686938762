import {useState, useEffect} from 'react';
import Post1 from "../../assets/img/angels/Imagen56.png";
import Post2 from "../../assets/img/angels/Imagen55.png";
import Post3 from "../../assets/img/angels/Imagen57.png";
import { FaAngleLeft, FaAngleRight} from "react-icons/fa";
import SocialBar from "../social-bar";

import './index.css';

const Carousel: React.FC = () => {
    const [positions, setPositions] = useState({active:1,back:0,next:2});
    const [viewCard, setViewCard] = useState(true);
    
    const positionClass = (i:number) => {
        switch(i){
            case positions.active: return "active";
            case positions.next: return "next";
            case positions.back: return "back";
        }
        return "";
    }

    const onChangeCard = (i:number) => {
        const value = i >= 0 && i <= 2 ? i : i < 0 ? 2 : 0; 
        setPositions({
            active: value,
            next: value + 1 <= 2 ? value + 1: 0,
            back: value - 1 >= 0 ? value - 1 : 2
        })
    }

    const toogleView = () =>{ setViewCard(!viewCard); }

    return (<>
        <div className={`card-section ${!viewCard ? "hidden" : ""}`} id="card-section" > 
            <div className={`angel-card ${positionClass(0)}`} id="angels-post-1" >  
                <div className="img-container">
                    <img src={Post1} alt="imagen alusiva a angeles"/>
                </div>
                <div className="detail">
                    <h2>¿Que son los Ángeles?</h2>
                    <hr/>
                    <p>Los Ángeles son seres sobrenaturales pertenecientes a una amplia jerarquía celestial que se ha hecho presente en varias religiones y mitologías atreves de los tiempos en la historia de la humanidad...</p>
                    <div className="view-more-button" onClick={toogleView}>Ver más</div>
                    <SocialBar />
                </div>                
            </div>            
            <div className={`angel-card ${positionClass(1)}`} id="angels-post-2" >  
                <div className="img-container">
                    <img src={Post2} alt="imagen alusiva a angeles"/>
                </div>
                <div className="detail">
                    <h2>¿Has visto sus señales?</h2>
                    <hr/>
                    <p>¿Cuántas veces durante el camino de tu vida y dentro de tu fe, te has sentado e incluso arrodillado a pedir a Dios una señal para saber que camino tomar? o ¿en que momentos de preocupación has pedido a tu ángel de la guarda que te proteja de los males que sientes venir o ... </p>
                    <div className="view-more-button" onClick={toogleView}>Ver más</div>
                    <SocialBar />
                </div>                
            </div>            
            <div className={`angel-card ${positionClass(2)}`} id="angels-post-3" >  
                <div className="img-container">
                    <img src={Post3} alt="imagen alusiva a angeles"/>
                </div>
                <div className="detail">
                    <h2>¿Deseas contactar tus ángeles? así puedes hacerlo</h2>
                    <hr/>
                    <p>No es nada extraño o ajeno para aquellos que somos creyentes de los ángeles no como deidad, pero si como un ser celeste que intercede por nosotros ante la justicia divina, el querer entablar una comunicación bien ...</p>
                    <div className="view-more-button" onClick={toogleView}>Ver más</div>
                    <SocialBar />
                </div>                
            </div>            
        </div>
        <div className={`angels-cr-pager ${!viewCard ? "hidden" : ""}`} id="angel-pager">
            <div className="icon-container" id="back" onClick={() => onChangeCard(positions.active - 1)}><FaAngleLeft /></div>
            <div className="nb-container">
                <span className={`indicator ${positionClass(0)}`} onClick={() => onChangeCard(0)}>1</span>
                <span className={`indicator ${positionClass(1)}`} onClick={() => onChangeCard(1)}>2</span>
                <span className={`indicator ${positionClass(2)}`} onClick={() => onChangeCard(2)}>3</span>
            </div>
            <div className="icon-container" id="next" onClick={() => onChangeCard(positions.active + 1)}><FaAngleRight /></div>
        </div>
        {positions.active === 0 && 
        <div className={`post-section ${viewCard ? "hidden" : ""}`}> 
            <div className="post-content" id="ct-post-1">
                <div className="header">
                    <h2>¿Que son los Ángeles?</h2>
                    <div>
                        <img src={Post1} alt="imagen alusiva a angeles"/>
                    </div>
                    <SocialBar backAction={toogleView} return />
                </div>                
                <div className="detail">
                    <p>Los Ángeles son seres sobrenaturales pertenecientes a una amplia jerarquía celestial que se ha hecho presente en varias religiones y mitologías atreves de los tiempos en la historia de la humanidad y cuyas funciones, cualidades y características van variando según cada cultura y nivel en la jerarquía celestial que se les otorga. </p><br/>
                    <p>Estos seres que son en si reconocidos como seres espirituales son normalmente nombrados como mensajeros de Dios ante la humanidad, lo cual es particularmente más notorio en las culturas o religiones monoteístas (creyentes de un solo Dios) como es el caso del catolicismo, en la cual se tiene bien concebido el concepto de ángel de la guarda, el cual por definición es la representación espiritual de ese ser que fue enviado con cada uno de nosotros para guardar y guiar sus pasos durante el camino de la vida.</p><br/>
                    <p>El concepto de ángel como ser que no hace parte de la humanidad, pero tampoco es en sí mismo una deidad abarca un concepto y estudio tan amplio que incluso existe una rama completa de la Teología que dedica su estudio a estos seres, esta rama es conocida como Angelología, por supuesto al igual que los estudios teológicos tiene sus diferencias y similitudes en distintas religiones como El Cristianismo, El Judaísmo y El Islam. </p><br/>
                    <p>Como hemos expresado al principio de este articulo los ángeles o mensajeros de Dios son nombrados no solamente por religiones, hablando expresamente, otras prácticas también ligadas a lo celestial o que se mueven en la línea temporal del cosmos como el Tarot que de hecho entrega un juego especializado llamado Tarot de los ángeles o la Cábala que como lectura antigua del judaísmo guarda entre sus cartas el nombre de 72 seres celestes o ángeles.</p><br/>
                    <p>Pero quien decidió darles este nombre tan divino que de repente se convierte en nuestros tiempos en una expresión de arte, adorno cualitativo para enaltecer la belleza de seres demasiado importantes en nuestra vida cotidiana, pues no lejos de la religión la palabra ángel procede del latín angelius que a su vez deriva del griego ángelos que traduce en nuestro tiempo mensajero. </p><br/>
                </div>
            </div>
        </div>}   
        {positions.active === 1 &&    
        <div className={`post-section ${viewCard ? "hidden" : ""}`}> 
            <div className="post-content" id="ct-post-1">
                <div className="header">
                    <h2>¿Has visto sus señales?</h2>
                    <div>
                        <img src={Post2} alt="imagen alusiva a angeles"/>
                    </div>
                    <SocialBar return backAction={toogleView}/>
                </div>                
                <div className="detail">
                    <p>¿Cuántas veces durante el camino de tu vida y dentro de tu fe, te has sentado e incluso arrodillado a pedir a Dios una señal para saber que camino tomar? o ¿en que momentos de preocupación has pedido a tu ángel de la guarda que te proteja de los males que sientes venir o que te lleve por el camino con bien cuando temes algún daño?, recuerdas esos momentos y ¿has pensado que ocurrió a tu alrededor en ese momento, que sentiste, que percibiste?, pues bien te contare algunas de las muchas señales que pueden indicar que tu petición ha sido escuchada.</p><br/>
                    <p>No lejos de la realidad en muchos momentos de desesperación solemos acudir a las oraciones y las suplicas de nuestro Dios o nuestros seres guardianes, tristemente suele ser esta la situación ya que en algún momento todos hemos dejado en el olvido que estas oraciones también existen para agradecer por cada paso que damos o prueba que superamos. Sin embargo, esto no quiere decir que nuestras oraciones no sean escuchadas y lo tradicional es que sean los ángeles, denominados mensajeros de Dios ante los hombres quienes cumplan esta labor de entregar el mensaje por el que hemos estado orando.</p><br/>
                    <p>Los ángeles son seres celestes que existentes en el cosmos y que tienen la habilidad de moverse entre lo visible y lo invisible, de allí que las señales que nos envían o respuestas que nos otorgan están generalmente relacionadas con nuestros 5 sentidos, es decir, que pueden ser percibidos con la vista, el olfato, el tacto, el odio e incluso el gusto, ellos se ocupan de entregar el mensaje, pero nunca de tomar la decisión por nosotros.</p><br/>
                    <p>Pues bien, abre bien tus sentidos, aumenta tu percepción y tu receptividad sin confundir por supuesto, estas son algunas de las señales que en algún momento te han llegado sin pensar o te llegaran y que son enviados por estos seres que se mueven a través de nuestro subconsciente.</p><br/>
                    <p>Plumas: El hallazgo de plumas en lugares poco comunes o mas bien anormales es siempre un recordatorio, un hermoso recordatorio de que tu ángel del aguarda se encuentra cerca o está a tú lado, incluso si esta es blanca en un lugar lleno de pájaros blancos, el hecho de que tu inconsciente te halla llevado a fijarte justamente en esta pluma, es un recordatorio para ti que no caminas solo.</p><br/>
                    <p>Aromas: Como bien lo dijimos los ángeles se comunican atreves de nuestros sentidas, ¿has pasado en algún momento o incluso estando simplemente en tu casa y de repente sientes un aroma dulce y encantador del cual no puedes explicar su origen? Al igual que nuestros ancestros pueden dar señales con olores conocidos, el perfume de tu madre, de tu abuela o algún olor muy característico, así mismo los ángeles pueden llevar a ti un dulce aroma a chocolate y menta o incluso los Arcángeles hacer que huelas a Flores en medio del fango solamente para avisarte que allí están y estas siendo escuchado.</p><br/>
                    <p>Música: Podrías pensar que se trata solo de el tema de moda y de hecho no estarías lejos de la realidad, pero si el tema que escuchas ¿no es la moda?, que pasa cuando no quieres saber de algo y cambias mil veces de estación, de lista de reproducción y casualmente suenan aquellas canciones que repiten y repiten un mismo tema. Pues quizá no sea casualidad, como bien hemos dicho los ángeles son seres que se mueven dentro de nuestro plano y nuestro inconsciente, pueden estarte hablando atreves de la música ya que no puedes oír propiamente su vos, estarte hablando de algo que necesitas hacer o afrontar, quizá esta contestando a eso que llevas dándole vueltas en tu cabeza por varios días. </p><br/>
                    <p>Calidez: Recuerda que los seres celestes son seres de energía pura, de luz divina, dado a su naturaleza cuando un ser espiritual no físico comienza a mover toda su energía con el fin de materializarse, toda esa fuerza vital empleada comienza a travesar las capas de energía existentes y generan una sensación repentina de calor. Por eso cuando de manera repentina tienes esa sensación de calor en tu cuerpo sin explicación alguna, no es temperatura y aun no llega el verano, no te preocupes, puede ser un ser celeste que se esta acercando a ti en señal de compañía y tranquilidad ante alguna situación en la que consciente o inconsciente mente estas pensando. </p><br/>
                    <p>Existen muchas otras señales con las que nos podremos encontrar en el camino, pero por ahora lo dejaremos hasta aquí y continuaremos en una segunda parte, recuerda no todo lo que veas son señales, pero tampoco todo lo que sientas es una simple casualidad. Abre tu percepción porque ellos te quieren guiar. </p><br/>
                </div>
            </div>
        </div>}
        {positions.active === 2 &&    
        <div className={`post-section ${viewCard ? "hidden" : ""}`}> 
            <div className="post-content" id="ct-post-1">
                <div className="header">
                    <h2>¿Deseas contactar tus ángeles? así puedes hacerlo</h2>
                    <div>
                        <img src={Post3} alt="imagen alusiva a angeles"/>
                    </div>
                    <SocialBar return backAction={toogleView}/>
                </div>                
                <div className="detail">
                    <p>No es nada extraño o ajeno para aquellos que somos creyentes de los ángeles no como deidad, pero si como un ser celeste que intercede por nosotros ante la justicia divina, el querer entablar una comunicación bien sea para agradecer por un acontecimiento que se escapaba de nuestro control o para pedir una guía ante una situación que ocupa nuestro día a día.</p><br/>
                    <p>Pues bien, estas son algunas sencillas maneras conocidas para poder entablar comunicación con nuestros ángeles que de seguro te ayudaran a cumplir el objetivo de escuchar su mensaje.</p><br/>
                    <p>Escribe una carta a tu ángel: No en vano se describe la poesía como el lenguaje del alma y es que es incluso descrito como terapia el plasmar con letras aquello que llega desde lo profundo de nuestro ser, nuestro corazón, nuestra alma y que no logramos expresarlo con el habla, con esta manifestación escribe una carta a tu ángel de la guarda con aquellos sentimientos, aquella historia o aquella duda  y espera, comienza a escribir todos los sentimiento que llegan como información para ti , las palabras, los pensamientos etc., todo aquello que aun quieras escribir una vez concluida tu carta. En algunas ocasiones o mas bien a algunas personas les viene bien cerrar los ojos mientras transcriben los mensajes que les llegan.</p><br/>
                    <p>Consulta un Oráculo: Aunque no siempre puedas verlos o distinguir sus señales, tus ángeles siempre están a la escucha de lo que quieras decirles pide a tu Ángel que te guie hacia el oráculo adecuado para ti, concéntrate y las cartas empataran su frecuencia vibratoria con tu pregunta, es una técnica de lo mas tradicional que se basa en el concepto de resonancia de energías y que entregara tu mensaje a través de la selección de la carta.</p><br/>
                    <p>Meditación: Si eres una persona que acostumbra a practicar la meditación seguramente habrás abierto algunos canales sensoriales o amplificado algunos sentidos, siendo este el caso podrás sentir claramente su respuesta a través de las sensaciones corporales, escuchar su voz en el silencio, recibir imágenes como si fuesen una película en medio de la oscuridad o una lluvia de información en tu mente. </p><br/>
                    <p>Platica en la ducha: Aunque suene un poco fuera de lo normal, es una técnica que tiene su base en el movimiento de la energía, cuando te bañas el agua que cae como lluvia sobre tu cuerpo va limpiando a su vez no solo exterior si no también la energía, este hecho en si mismo crea un canal conductor de energía, sutil pero suficiente para percibir de una forma mas sencilla a tus ángeles. ¡No lo tomes a broma e inténtalo!</p><br/>
                    <p>Señales: Como mencionamos antes, aunque no puedas verlos, tus ángeles siempre estarán a la escucha, así que al salir de casa dales el permiso para actuar en tu día, pídeles señales claras que puedas identificar sin confundirlas y por supuesto si tienes dudas concretas, entonces hazles las preguntas de forma clara y aumenta tu percepción para atender a sus respuestas. </p><br/>
                </div>
            </div>
        </div>}
    </>);
}

export default Carousel;