import ArticleUrl from '../../assets/img/articles/Imagen39.png';
import VideoCoverPage from '../../assets/img/articles/videncia.png';
import VideoClip from '../../assets/video/unDon.mp4';
import './index.css'

const FeaturedArticles: React.FC = () => {
    return (
        <section className="featured-articles">
            <div className="article">
                <div className="media-container">
                    <h3>Videncia</h3>                            
                    <div className="img-container">
                        <video controls autoPlay={false} poster={VideoCoverPage}>
                            <source src={VideoClip} type="video/mp4"/>                     
                        </video> 
                    </div>
                </div>
                <div className="detail-container">
                    <p>La videncia consiste en ver lo que otros no, es un don que normalmente pasa de generación en generación, no se estudia en ninguna escuela, en un regalo divino que pocos tienen el honor de poseer.</p>
                </div>
            </div>
            <div className="article">
                <div className="media-container">
                    <h3>Amor</h3>                            
                    <div className="img-container">
                        <img src={ArticleUrl} alt="Imagen relativa al amor"/>
                    </div>
                </div>
                <div className="detail-container">
                    <p>¿Sientes que el amor no es para ti?, tú relación no es la misma de antes?, ¿sigues buscando el amor en la persona equivocada?, cierra ciclos y conoce las posibilidades que tiene el futuro para ti.</p>
                    <button>Ver Más</button>
                </div>
            </div>
        </section>
    );
}

export default FeaturedArticles;