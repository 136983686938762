import "./index.css";

const Gallery: React.FC = () => {
    return (
        <>
            <div className="biography-gallery">
                <h2>Videos de Rene</h2>
                <div>
                    <iframe width="365" height="250"
                        src="https://www.youtube.com/embed/AOzx0Okvk0M">
                    </iframe>
                    <iframe width="365" height="250"
                        src="https://www.youtube.com/embed/ATU2X8_DJbY">
                    </iframe>
                    <iframe width="365" height="250"
                        src="https://www.youtube.com/embed/igz2LNnveHc">
                    </iframe>
                </div>
            </div>
        </>
    );
}

export default Gallery;