import Header from "../components/header";
import Footer from "../components/footer";
import RitualsGallery from "../components/rituals";

const Rituals: React.FC = () => {
    return (
        <>
        <Header />
        <main className="rituals-main">
            <RitualsGallery />
        </main>
        <Footer />
        </>
    );
}

export default Rituals;