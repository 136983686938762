import {useState} from 'react';
import './index.css';

const RitualsGallery: React.FC = () => {
    const [viewCard, setViewCard] = useState(true);
    const [indexActive, setIndexActive] = useState(-1);

    const toogleCard = (i:number) => {        
        setIndexActive(i);
        setViewCard(!viewCard);
    }
    return (
        <>
        <div className={`rituals-content  ${!viewCard ? 'hidden-mod' : ''}`}>
            <div className={`ritual-card ${viewCard && indexActive === 0 ? 'active' : ''}`} onClick={() => toogleCard(0)}>
                <h2>Ritual del amor</h2>
                <img width="300" height="300" src="https://www.nuevatribuna.es/asset/thumbnail,992,558,center,center//media/nuevatribuna/images/2020/09/25/2020092511301045098.jpg" />
            </div>
            <div className={`ritual-card ${viewCard && indexActive === 1 ? 'active' : ''}`} onClick={() => toogleCard(1)}>
                <h2>Ritual de la abundancia</h2>
                <img width="300" height="300" src="https://static.mujerhoy.com/www/multimedia/201806/22/media/cortadas/ritual-dinero-noche-de-san-juan-k9dB-U60126227325gOE-624x467@MujerHoy.jpg" />
            </div>
            <div className={`ritual-card ${viewCard && indexActive === 2 ? 'active' : ''}`} onClick={() => toogleCard(2)}>
                <h2>Ritual de la suerte</h2>
                <img width="300" height="300" src="https://www.loteriamanises.com/blog/wp-content/uploads/2017/12/ritual-para-atraer-buena-suerte.jpg" />
            </div>
            <div className={`ritual-card ${viewCard && indexActive === 3 ? 'active' : ''}`} onClick={() => toogleCard(3)}>
                <h2>Ritual de la familia</h2>
                <img width="300" height="300" src="https://aliciagarciapsicologa.com/wp-content/uploads/2017/01/family-g819ad7ee1_1280-e1636628493658.jpg" />
            </div>
            <div className={`ritual-card ${viewCard && indexActive === 4 ? 'active' : ''}`} onClick={() => toogleCard(4)}>
                <h2>Ritual de limpieza africano</h2>
                <img width="300" height="300" src="https://www.clarin.com/img/2015/10/07/claima20151007-0257-caldero-foto-istock___BJLeFVxEl_340x340.jpg" />
            </div>
            <div className={`ritual-card ${viewCard && indexActive === 5 ? 'active' : ''}`} onClick={() => toogleCard(5)}>
                <h2>Ritual del Plomo</h2>
                <img width="300" height="300" src="https://resizer.glanacion.com/resizer/3l2Sk_rZpPq4dgwC0XYDIVpQQJo=/768x0/filters:quality(80)/cloudfront-us-east-1.images.arcpublishing.com/lanacionar/ISYM73FNHBFR5L5JUUJVPCKEVI.jpg" />
            </div>
        </div>
        <div id="description" className={`${!viewCard ? 'active' : ''}`}>
            {indexActive === 0 &&
            <div className="description-content">
                <h2>Ritual del amor</h2><hr/>
                <img width="300" height="300" src="https://www.nuevatribuna.es/asset/thumbnail,992,558,center,center//media/nuevatribuna/images/2020/09/25/2020092511301045098.jpg" />
                <p>¿Sientes que tu pareja esta cada día más distante?</p>
                <p>¿Sospechas que te es infiel?</p>
                <p>¿Has perdido la confianza que tenías en tu persona especial?</p>
                <p>¿Quieres que tu ser amano regrese a ti?</p>
                <p>¿Quieres conocer el amor de tu vida?</p>
                <p>Disfruta del amor en pareja, recupera la complicidad, la armonía y la pasión. Encuentra tu alma gemela.</p>
                <p>Los rituales te permiten crear uniones de amor más fuerte, recuperar el amor que has perdido y volver a ser feliz, Abren las puertas para que el amor llegue a tu vida, derriban los bloqueos que existan y te ayudan a recuperar lo que por justicia divina te pertenece.</p>
                <div id="close" onClick={() => toogleCard(-1)}>X</div>
            </div>}
            {indexActive === 1 &&
            <div className="description-content">
                <h2>Ritual de la abundancia</h2><hr/>
                <img width="300" height="300" src="https://static.mujerhoy.com/www/multimedia/201806/22/media/cortadas/ritual-dinero-noche-de-san-juan-k9dB-U60126227325gOE-624x467@MujerHoy.jpg" />
                <p>¿Tu situación económica no va bien?</p>
                <p>¿Estas bloqueado?</p>
                <p>¿El dinero se te va de las manos?</p>
                <p>El ritual de abundancia romperá las barreras que en este momento te impiden tener prosperidad, te dará nuevas oportunidades para que el dinero llegue a tu vida y se quede, te limpiara de las energías negativas que impiden tener fluidez y abundancia.</p>
                <div id="close" onClick={() => toogleCard(-1)}>X</div>
            </div>}
            {indexActive === 2 &&
            <div className="description-content">
                <h2>Ritual de la suerte</h2><hr/>
                <img width="300" height="300" src="https://www.loteriamanises.com/blog/wp-content/uploads/2017/12/ritual-para-atraer-buena-suerte.jpg" />
                <p>¿Sientes que todo en tu vida va mal?</p>
                <p>¿Por más que te esfuerzas no tienes los resultados que buscas?</p>
                <p>¿No eres feliz?</p>
                <p>El ritual de la suerte le dará un nuevo giro a tu vida, te abrirá las puertas del éxito y derrumbara las barreras que te han impedido cumplir tus objetivos.</p> 
                <p>Este ritual alineara las energías para que todo lo que siempre has deseado y por lo que has trabajo sea posible.</p>
                <div id="close" onClick={() => toogleCard(-1)}>X</div>
            </div>}
            {indexActive === 3 &&
            <div className="description-content">
                <h2>Ritual de la familia</h2><hr/>
                <img width="300" height="300" src="https://aliciagarciapsicologa.com/wp-content/uploads/2017/01/family-g819ad7ee1_1280-e1636628493658.jpg" />
                <p>¿Existe desunión en la familia?</p>
                <p>¿Las envidias familiares no te han dejado avanzar?</p>
                <p>¿Hay desacuerdos que llevan a problemas?</p>
                <p>El ritual de la familia te ayuda a afianzar los lazos familiares, ayuda a la armonía en el hogar, aleja las malas energías causadas por envidias, desacuerdos y magia negra, recupera la confianza y la unión que te permitirá disfrutar del amor y la felicidad familiar.</p>
                <div id="close" onClick={() => toogleCard(-1)}>X</div>
            </div>}
            {indexActive === 4 &&
            <div className="description-content">
                <h2>Ritual de limpieza africano</h2><hr/>
                <img width="300" height="300" src="https://www.clarin.com/img/2015/10/07/claima20151007-0257-caldero-foto-istock___BJLeFVxEl_340x340.jpg" />
                <p>¿La magia negra está destruyendo tu vida?</p>
                <p>¿Hay personas que quieren acabar con tu familia?</p>
                <p>¿Alejaron tu ser amado con magia negra?</p>
                <p>¿La magia negra te cerro todas las puertas?</p>
                <p>El ritual de limpieza africano te liberara de la magia negra que hace que todo en tu vida vaya mal, te protegerá de santerismo, mal de ojo y brujería. Con este ritual toda magia oscura que esté presente en tu vida se alejara y recuperaras todo lo que te ha sido arrebatado.</p>
                <div id="close" onClick={() => toogleCard(-1)}>X</div>
            </div>}
            {indexActive === 5 &&
            <div className="description-content">
                <h2>Ritual del Plomo</h2><hr/>
                <img width="300" height="300" src="https://www.nuevatribuna.es/asset/thumbnail,992,558,center,center//media/nuevatribuna/images/2020/09/25/2020092511301045098.jpg" />
                <p>El ritual del plomo es un ritual ancestral que permite limpiar las malas energía y todo tipo de magia negra que esté presente. Por medio de este ritual tu vida cambiara, ya que todas las puertas que te fueron cerradas nuevamente se abrirán y recuperaras por justicia divina todo lo que te fue quitado. Este ritual te permitirá tener una protección para que ningún tipo de magia ni energía negativa te dañe. 
                </p>
                <div id="close" onClick={() => toogleCard(-1)}>X</div>
            </div>}
        </div>
        </>
    );
}

export default RitualsGallery;