import SocialBar from "../social-bar";
import Mago from "../../assets/img/blog/mago.jpg"
import { FaUserCircle, FaBookReader } from "react-icons/fa";
import "./index.css";
import { Link } from "react-router-dom";

const PostCardGallery: React.FC = () => {
    return(
        <main className="blog-main">
            <div className='blog-card'>
                <div className='left-column center'>
                    <div className='img-container center'>
                        <img width={150} height={150} src={Mago}></img>
                    </div>
                    <div className="footer-card">
                        <div>
                            <h5>Febrero 17, 2023</h5>
                        </div> 
                        <SocialBar />
                    </div>
                </div>
                <div className='detail-container'>
                    <h2>¿Como te ayuda el tarot?</h2>
                    <hr></hr>
                    <p>¿Sientes curiosidad por el futuro?, ¿tu mente inquieta no deja de trazar distintos caminos que no sabes dónde te llevaran?, ¿sientes que hay puertas que no puedes abrir o simplemente en el horizonte todo se cubre de una espesa niebla y no sabes hacia dónde mirar? Estas y muchas dudas más que confieren a temas del amor, la vida, la salud y la economía, son en el ser parte de su misma humanidad</p>

                    <div className="user-container">
                        <div className='user-name'>
                            <span><Link to={"/tarot/comoteayudaeltarot"} className="link">Ver más</Link></span>
                        </div>
                    </div>
                </div>
                <div className="view-post">
                                    
                </div>
            </div>
        </main>
    );
}

export default PostCardGallery;