import { FaWhatsapp, FaCcVisa, FaPhoneAlt, FaFacebookF, FaInstagram, FaYoutube } from 'react-icons/fa';
import './index.css';

const Footer: React.FC = () => {
    return (
        <footer className="call-button-bar">                
            <div className="call-button-container">
                <a href='tel:+4806-433-025'>
                    <FaPhoneAlt />
                    <span>806-433-025</span>
                </a>
            </div>
            <div className="call-button-container">
                <a href='tel:931-221-325'>
                    <FaCcVisa />
                    <span>931-221-325</span>
                </a>
            </div>
            <div className="call-button-container">
                <a href=''>
                    <FaWhatsapp />
                    <span>(351) 926-178-651</span>
                </a>
            </div>
            <div className="social-network-bar">
                <div className="social-item">
                    <a href='https://www.facebook.com/elfuturoentumanotarot' target={"_blank"} rel={"noreferrer"}><FaFacebookF /></a>
                </div>
                <div className="social-item">
                    <a href='https://www.instagram.com/eldivinorene' target={"_blank"} rel={"noreferrer"}><FaInstagram /></a>
                </div>
                <div className="social-item">
                    <a href='https://www.youtube.com/@RenephilippevilmontEs2014' target={"_blank"} rel={"noreferrer"}><FaYoutube /></a>
                </div>
            </div>
        </footer>
    )
}

export default Footer;