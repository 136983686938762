import Footer from "../components/footer";
import Header from "../components/header";
import FeaturedArticles from "../components/featured-articles";
import HomeBlog from "../components/home-blog";
import Cavinet from "../components/cavinet";

const Home: React.FC = () => {
    return (<>
        <Header/>
        <header>
            <div className="header-overlay">
                <p>Una Llamada puede cambiar tu vida.</p>
                <p>No tengas miedo de conocer tu futuro</p>
            </div>            
        </header>
        <main>
            <FeaturedArticles />
            <HomeBlog />
            <Cavinet />
        </main>
        <Footer />
    </>);
}
export default Home;