import Header from "../components/header";
import Footer from "../components/footer";
import PostCardGallery from "../components/poast-card-gallery";


const BlogTarot: React.FC = () => {
    return (
        <>
        <Header />
        <PostCardGallery />
        <Footer />
    </>)
}

export default BlogTarot