import Footer from "../components/footer";
import Header from "../components/header";
import BiographyContent from "../components/biography";
import Gallery from "../components/gallery";

const Biography: React.FC = () => {
    return (<>
        <Header />
        <main className="biography-main">
            <BiographyContent />
            <Gallery />
        </main> 
        <Footer />
    </>);
}

export default Biography;