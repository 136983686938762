import './index.css';

const HomeBlog: React.FC = () => {
    return (
        <section className="center-section">
            <div className="title-container">
                <h2>Transforma tus energias</h2>
            </div>
            <div className="content-container">
                <p>El intercambio dinámico consiste en que se recibe lo que se ha sembrado y se siembra lo que se tiene en su ser, especialmente en su corazón. En términos literales,  las semillas deben dar su información genética o inteligencia, por llamarlo así, al suelo fértil, conectarse con el aire y el agua, para no quedar reprimidos como semillas y esta forma germinar como árboles y luego bosques.</p><br/>
                <p>Así mismo, las personas no deben reprimir sus semillas de amor y por el contrato deben aprender a dar sin prejuicios. Cuando uno siembra amor, tendrá un bosque de amor. Por esto la manera más fácil de obtener lo que buscas en la vida es ayudar a otros a obtener lo que ellos buscan.</p><br/>
                <p>El momento en que entiendas esta verdad y te olvides del egoísmo los rencores y resentimientos recibirás los mejores regalos del universo.</p>
            </div>
            <div className="poster-container">
                <h2>
                    La videncia y el Tarot pueden abrirte caminos, Pero no olvides que tienes un potencial mucho más grande de lo que crees. Así que procura ser mejor cada día
                </h2>
            </div>
        </section>
    )
}

export default HomeBlog;