import Rene from "../../assets/img/cavinet/Rene.jpeg";
import { FaComments , FaHeart, FaShareAlt} from "react-icons/fa";
import SocialBar from "../social-bar";
import './index.css';

const BiographyContent: React.FC = () => {
    return (
        <>
        <div className="biography-content">                            
                <h1>Rene Philippe Vilmont</h1>
                <img src={Rene} width="300" height="300" style={{float:"left"}}/>
                <p>
                El Divino, René Philippe Vilmont, nació el 2 de septiembre de 1969 en el seno de una familia francesa que ya contaba con 8 generaciones de médiums y videntes. A una edad temprana René empezó a manifestar los dones que se transmitían de generación en generación. todo ocurrió cuando él contaba con tan solo 3 años edad, mientras en un almuerzo familiar, René se quedó preocupado porque a su abuela no le habían puesto cubiertos. El, ante este gesto le pregunto a su madre, que porque su abuela no tenía cubiertos.
                </p><br/>
                <p>
                Esto sería normal en cualquier casa, pero teniendo en cuenta que su abuela había fallecido hacía ya varios años, demostró a su familia que él había sido elegido para continuar con la herencia del don de la clarividencia. Al querer dedicarse al mundo del tarot y la videncia, tal y como es ley en Francia, tenía que pasar unos exámenes previos para demostrar que su don es genuino y no una imitación.
                </p><br/> 
                <p>
                Así pues, tras pasar exhaustivas pruebas obtuvo la aprobación por el gobierno francés. prueba de ello es que posteriormente fue llamado por el jefe del estado francés François Mitterrand. Al haberle sido de gran utilidad al señor Mitterrand su fama se extendió en el mundo de los famosos, y artistas y actores de la talla de Madonna, Paulina Rubio y Regina Dos Santos han requerido sus servicios. incluso en más de una ocasión con la excusa de invitarlo a una fiesta, o de vacaciones, siempre han aprovechado a René para que les mire el futuro a través de sus cartas.
                </p><br/>
                <p>
                Su paso por la televisión española fue intenso, ya que muchos de los llamados “videntes” empezaron a imitar cosas que hacía René. Las cadenas de televisión donde René ha estado: Telecinco, Fdf, Diquesi.tv, La sexta, Canal Cuatro, La Tienda en Casa y M3tv
                </p><br/>
                <p>
                El hecho de que René no colabore ocasionalmente en algún programa de televisión es por su negación a mentir, ya que en ciertos programas que querían ganar más audiencia le incitaban a mentir. René ante tal negativa de mentir a la gente y no queriendo ser un espectáculo de feria fue siendo apartado, dando paso a otros “payasos” que actualmente si reclaman en shows de televisión para entretener a la gente.
                </p><br/>
                <SocialBar />
            </div>
        </>
    );
}

export default BiographyContent;