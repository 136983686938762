import Logo from '../../assets/img/logo/logo.png';
import { Link } from 'react-router-dom'; 

import './index.css'
const Header: React.FC = () => {
    return (
        <nav id="main-nav">
            <div className="logo-container">
                <img src={Logo} alt="Logo el divino Rene" />
            </div>
            <ul className="nav-list">
                <li><Link to="/home"><span>Inicio</span></Link></li>
                <li><Link to="/rene"><span>Rene</span></Link></li>
                <li><Link to="/angeles"><span>Angeles</span></Link></li>
                <li><Link to="/rituales"><span>Rituales</span></Link></li>
                <li><Link to="/tarot"><span>Tarot</span></Link></li>
            </ul>
        </nav>    
    );
}

export default Header;