import { Routes, Route } from "react-router-dom";
import Home from "../views/home";
import Biography from "../views/biography";
import Angels from "../views/angels";
import Rituals from "../views/rituals";
import BlogTarot from "../views/tarot";
import ComoTeAyudaElTarot from "../posts/comoteayudaeltarot";


const AppRouter = () => {
    return (
        <Routes>
            <Route path="/" element={<Home />}/>
            <Route path="/rene" element={<Biography />}/>
            <Route path="/angeles" element={<Angels />}/>
            <Route path="/rituales" element={<Rituals />}/>
            <Route path="/tarot" element={<BlogTarot />}/>   
            <Route path="/tarot/comoteayudaeltarot" element={<ComoTeAyudaElTarot />}/>
            <Route path="*" element={<Home />}/>
        </Routes>
    );
}

export default AppRouter;