import { FaComments , FaHeart, FaShareAlt, FaRegArrowAltCircleLeft} from "react-icons/fa";
import './index.css';

const SocialBar: React.FC<any> = (props) => {
    return (
        <div className="social-bar">
            {
                props.return && <div className="footer-item return" id="return" onClick={props.backAction}>
                    Volver
                </div>
            }
            <div className="footer-item"><FaComments/><span>0</span></div>
            <div className="footer-item"><FaHeart /><span>0</span></div>
            <div className="footer-item"><FaShareAlt /><span></span></div>
        </div>
    );
}

export default SocialBar;