import Header from "../components/header";
import Footer from "../components/footer";
import Post from "../components/post";

const ComoTeAyudaElTarot: React.FC = () => {
    return(<>
        <Header />
        <main className="post-main">
            <Post />
        </main>
        <Footer />
    </>);
}
export default ComoTeAyudaElTarot;