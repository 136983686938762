import SocialBar from '../social-bar';
import Mago from "../../assets/img/blog/mago.jpg"

import './index.css';
const Post: React.FC = () => {
    return(
        <>
        <div className="post-content">
                <h1>¿Como te ayuda el tarot?</h1>
                <p>¿Sientes curiosidad por el futuro?, ¿tu mente inquieta no deja de trazar distintos caminos que no sabes dónde te llevaran?, ¿sientes que hay puertas que no puedes abrir o simplemente en el horizonte todo se cubre de una espesa niebla y no sabes hacia dónde mirar? Estas y muchas dudas más que confieren a temas del amor, la vida, la salud y la economía, son en el ser parte de su misma humanidad, ¡no lees esto hoy por casualidad!</p><br/>
                <p>El Tarot es por tradición un medio de consulta de hechos presentes, pasados y futuros reconocida sobre todo por su poder premonitorio, sin embargo, estos son concepto que quedan cortos a los beneficios espirituales que la lectura del Tarot en compañía de Dones como la Videncia y ciencias como la cartomancia y la numerología pueden brindar a tu vida y tu tranquilidad.  </p>
                <div className="blog-head">
                    <div className="img-container">
                        <img width={150} height={150} src={Mago}></img>
                    </div>
                    <h3>El Tarot es por tradición un medio de consulta de hechos presentes, pasados y futuros </h3>
                </div>
                <p>Dicho de otro modo, el Tarot en manos de un vidente te ayudara a despejar esas dudas que roban tu tranquilidad y te impiden ver los caminos que tienes a tu alcance, no se trata de lo que quieras escuchar, es el mensaje que existe para ti, la guía de quien te quiere ayudar a salir del caos en que se encuentre tu mente, la luz que disipa esa niebla y te permitirá tomar mejores decisiones que lleven directamente a tu felicidad, a encontrar lo que buscas, a obtener lo que esperas. </p>
                <SocialBar />
            </div>
        </>
    );
}

export default Post;