import Alicia from '../../assets/img/cavinet/Alicia.jpg';
import Candela from '../../assets/img/cavinet/Candela.jpg';
import Carmela from '../../assets/img/cavinet/Carmela.jpg';
import Conchi from '../../assets/img/cavinet/Conchi.jpg';
import Javier from '../../assets/img/cavinet/Javier.jpg';
import Luz from '../../assets/img/cavinet/Luz.jpg';
import Maika from '../../assets/img/cavinet/Maika.jpg';
import Malena from '../../assets/img/cavinet/Malena.jpg';
import Mar from '../../assets/img/cavinet/Mar.jpg';
import Maria from '../../assets/img/cavinet/Maria.jpg';
import Rubi from '../../assets/img/cavinet/Rubi.jpg';

import './index.css';

const Cavinet: React.FC = () => {
    return (
        <section className="cavinet-section">
            <div className="title-container">
                <h2>Equipo de Tarotistas</h2>
            </div>
            <div className="intro-container">
                <p>Nuestro equipo de tarotistas es el mejor en Europa, son profesionales que te guiaran, resolverán tus inquietudes y te mostraran las posibilidades que te brinda el futuro.</p><br/>
                <p>Con solo 15€ tendrás la posibilidad de una consulta totalmente privada con el guía espiritual que tu elijas y tendrás las respuestas que necesitas en el amor, el trabajo, la familia, el negocio y las relaciones interpersonales.</p>
            </div>
            <div className="cavinet-cards">
                <div className="card-container">
                    <div className="name-container">
                        <h4>Mar</h4>
                    </div>
                    <div className="img-container" style={{backgroundImage: `url('${Mar}')`}}>
                    </div>
                    <div className="detail-container">
                        <span>9:30 a 14:00</span>
                    </div>
                </div>
                <div className="card-container">
                    <div className="name-container">
                        <h4>Mfaria</h4>
                    </div>
                    <div className="img-container" style={{backgroundImage:`url('${Maria}')`}}>
                    </div>
                    <div className="detail-container">
                        <span>10:00 a 15:00</span>
                    </div>
                </div>
                <div className="card-container">
                    <div className="name-container">
                        <h4>Alicia</h4>
                    </div>
                    <div className="img-container" style={{backgroundImage:`url('${Alicia}')`}}>
                    </div>
                    <div className="detail-container">
                        <span>10:30 a 17:00</span>
                    </div>
                </div>
                <div className="card-container">
                    <div className="name-container">
                        <h4>Carmela</h4>
                    </div>
                    <div className="img-container" style={{backgroundImage:`url('${Carmela}')`}}>
                    </div>
                    <div className="detail-container">
                        <span>11:00 a 14:00</span>
                        <span>18:00 a 23:00</span>
                    </div>
                </div>
                <div className="card-container">
                    <div className="name-container">
                        <h4>Rubi</h4>
                    </div>
                    <div className="img-container" style={{backgroundImage:`url('${Rubi}')`}}>
                    </div>
                    <div className="detail-container">
                        <span>11:30 a 14:00</span>
                        <span>15:30 a 19:00</span>
                    </div>
                </div>
                <div className="card-container">
                    <div className="name-container">
                        <h4>Conchi</h4>
                    </div>
                    <div className="img-container" style={{backgroundImage:`url('${Conchi}')`}}>
                    </div>
                    <div className="detail-container">
                        <span>16:00 a 23:00</span>
                    </div>
                </div>
                <div className="card-container">
                    <div className="name-container">
                        <h4>Malena</h4>
                    </div>
                    <div className="img-container" style={{backgroundImage:`url('${Malena}')`}}>
                    </div>
                    <div className="detail-container">
                        <span>16:00 a  01:00</span>
                    </div>
                </div>
                <div className="card-container">
                    <div className="name-container">
                        <h4>Javier</h4>
                    </div>
                    <div className="img-container" style={{backgroundImage:`url('${Javier}')`}}>
                    </div>
                    <div className="detail-container">
                        <span>17:00 a 23:00</span>
                    </div>
                </div>
                <div className="card-container">
                    <div className="name-container">
                        <h4>Maika</h4>
                    </div>
                    <div className="img-container" style={{backgroundImage:`url('${Maika}')`}}>
                    </div>
                    <div className="detail-container">
                        <span>18:00 a 6:00</span>
                    </div>
                </div>
                <div className="card-container">
                    <div className="name-container">
                        <h4>Candela</h4>
                    </div>
                    <div className="img-container" style={{backgroundImage:`url('${Candela}')`}}>
                    </div>
                    <div className="detail-container">
                        <span>20:00 a 03:00</span>
                    </div>
                </div>
                <div className="card-container">
                    <div className="name-container">
                        <h4>Luz</h4>
                    </div>
                    <div className="img-container" style={{backgroundImage:`url('${Luz}')`}}>
                    </div>
                    <div className="detail-container">
                        <span>6:00 a 8:00</span>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Cavinet;