import Header from "../components/header";
import Footer from "../components/footer";
import Carousel from "../components/carousel";

const Angels: React.FC = () => {
    return (
        <>
        <Header />
        <main className="angels-main">
            <Carousel />
        </main>
        <Footer />
        </>
    );
}

export default Angels;